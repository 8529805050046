.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 380px) {
  .container {
    max-width: 380px;
  }
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

.prose {
  color: black;
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 1.25rem 0;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose :where(a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.625rem;
  border-bottom-color: #00D659;
  border-bottom-width: 2px;
  transition: background-color 0.15s ease;
}

.prose :where(strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 500;
}

.prose :where(a strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}

.prose :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-inline-start-width: 0.25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  font-size: 2.25rem;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 2.5rem;
  letter-spacing: -0.04em;
}

@media (min-width: 992px) {
  .prose :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    font-size: 3.5rem;
    letter-spacing: -0.04em;
    line-height: 3.75rem;
  }
}

.prose :where(h1 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}

.prose :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  font-size: 1.75rem;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 2rem;
  letter-spacing: -0.04em;
}

@media (min-width: 992px) {
  .prose :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    font-size: 2.75rem;
    letter-spacing: -0.04em;
    line-height: 3rem;
  }
}

.prose :where(h2 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}

.prose :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 2rem;
  letter-spacing: -0.04em;
}

@media (min-width: 992px) {
  .prose :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    font-size: 2rem;
    letter-spacing: -0.04em;
    line-height: 2.25rem;
  }
}

.prose :where(h3 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.prose :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5rem;
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .prose :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    font-size: 1.5rem;
    letter-spacing: -0.04em;
    line-height: 2rem;
  }
}

.prose :where(h4 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.prose :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  padding-inline-start: 0.375em;
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before {
  content: "`";
}

.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-inline-start: 1.1428571em;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before {
  content: none;
}

.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.prose :where(thead):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

.prose :where(tbody tr):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(th, td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  text-align: start;
}

.prose :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: black;
  --tw-prose-bullets: black;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.5em;
  padding-inline-start: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-top: 0.5714286em;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose :where(h5):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.prose :where(h6):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.prose :where(a:hover):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  background-color: #00D659;
}

.prose :where(p a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin: 0;
}

@media (min-width: 480px) {
  .prose :where(p a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    margin: 0;
  }
}

.prose :where(p a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  display: inline;
  font-weight: inherit;
}

.prose :where(ul li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 0.5rem 0;
}

.prose :where(li a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: inherit;
}

.prose :where(ul[is="purple-tick-icon"] li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  display: flex;
  list-style-type: none;
}

.prose :where(ul[is="purple-tick-icon"] li):not(:where([class~="not-prose"],[class~="not-prose"] *))::before {
  content: url('/images/purple-tick-icon.svg');
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
}

.prose :where(ul li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin: 0px;
}

.prose :where(ol li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 0.5rem 0;
}

.prose :where(ol li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin: 0px;
}

.prose :where(h1 a, h2 a, h3 a, h4 a, h5 a, h6 a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  transition: none;
  background: none;
}

.prose-sm {
  font-size: 0.875rem;
  line-height: 1.7142857;
}

.prose-sm :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}

.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.prose-sm :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.1111111em;
}

.prose-sm :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}

.prose-sm :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}

.prose-sm :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}

.prose-sm :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}

.prose-sm :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.prose-sm :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.prose-sm :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.prose-sm :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8571429em;
  border-radius: 0.3125rem;
  padding-top: 0.1428571em;
  padding-inline-end: 0.3571429em;
  padding-bottom: 0.1428571em;
  padding-inline-start: 0.3571429em;
}

.prose-sm :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8571429em;
}

.prose-sm :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
}

.prose-sm :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
}

.prose-sm :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding-top: 0.6666667em;
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-inline-start: 1.5714286em;
}

.prose-sm :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-inline-start: 1.5714286em;
}

.prose-sm :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}

.prose-sm :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4285714em;
}

.prose-sm :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4285714em;
}

.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}

.prose-sm :where(.prose-sm > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}

.prose-sm :where(.prose-sm > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}

.prose-sm :where(.prose-sm > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}

.prose-sm :where(.prose-sm > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}

.prose-sm :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}

.prose-sm :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}

.prose-sm :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.2857143em;
  padding-inline-start: 1.5714286em;
}

.prose-sm :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}

.prose-sm :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.5;
}

.prose-sm :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-top: 0.6666667em;
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.prose-sm :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}

.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.7777778;
}

.prose-lg :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.2222222em;
  line-height: 1.4545455;
  margin-top: 1.0909091em;
  margin-bottom: 1.0909091em;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  padding-inline-start: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.8333333em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.6666667em;
  margin-top: 1.8666667em;
  margin-bottom: 1.0666667em;
  line-height: 1.3333333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.3333333em;
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}

.prose-lg :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.prose-lg :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.prose-lg :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.prose-lg :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
  border-radius: 0.3125rem;
  padding-top: 0.2222222em;
  padding-inline-end: 0.4444444em;
  padding-bottom: 0.2222222em;
  padding-inline-start: 0.4444444em;
}

.prose-lg :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8666667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.75;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.375rem;
  padding-top: 1em;
  padding-inline-end: 1.5em;
  padding-bottom: 1em;
  padding-inline-start: 1.5em;
}

.prose-lg :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.5555556em;
}

.prose-lg :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.5555556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4444444em;
}

.prose-lg :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4444444em;
}

.prose-lg :where(.prose-lg > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.prose-lg :where(.prose-lg > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}

.prose-lg :where(.prose-lg > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.prose-lg :where(.prose-lg > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}

.prose-lg :where(.prose-lg > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.prose-lg :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}

.prose-lg :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}

.prose-lg :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.6666667em;
  padding-inline-start: 1.5555556em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 3.1111111em;
  margin-bottom: 3.1111111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0.75em;
  padding-bottom: 0.75em;
  padding-inline-start: 0.75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-top: 0.75em;
  padding-inline-end: 0.75em;
  padding-bottom: 0.75em;
  padding-inline-start: 0.75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-lg :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
  margin-top: 1em;
}

.prose-lg :where(.prose-lg > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose-lg > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-xl {
  font-size: 1.25rem;
  line-height: 1.8;
}

.prose-xl :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose-xl :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em;
}

.prose-xl :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1.0666667em;
}

.prose-xl :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 2.8em;
  margin-top: 0;
  margin-bottom: 0.8571429em;
  line-height: 1;
}

.prose-xl :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.8em;
  margin-top: 1.5555556em;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.prose-xl :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 1.5em;
  margin-top: 1.6em;
  margin-bottom: 0.6666667em;
  line-height: 1.3333333;
}

.prose-xl :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.8em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.prose-xl :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-xl :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
  border-radius: 0.3125rem;
  padding-top: 0.25em;
  padding-inline-end: 0.4em;
  padding-bottom: 0.25em;
  padding-inline-start: 0.4em;
}

.prose-xl :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
}

.prose-xl :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.8611111em;
}

.prose-xl :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
}

.prose-xl :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
  line-height: 1.7777778;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.5rem;
  padding-top: 1.1111111em;
  padding-inline-end: 1.3333333em;
  padding-bottom: 1.1111111em;
  padding-inline-start: 1.3333333em;
}

.prose-xl :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding-inline-start: 1.6em;
}

.prose-xl :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding-inline-start: 1.6em;
}

.prose-xl :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}

.prose-xl :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4em;
}

.prose-xl :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0.4em;
}

.prose-xl :where(.prose-xl > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.prose-xl :where(.prose-xl > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
}

.prose-xl :where(.prose-xl > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}

.prose-xl :where(.prose-xl > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
}

.prose-xl :where(.prose-xl > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}

.prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.prose-xl :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose-xl :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 1.2em;
}

.prose-xl :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0.6em;
  padding-inline-start: 1.6em;
}

.prose-xl :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2.8em;
  margin-bottom: 2.8em;
}

.prose-xl :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
  line-height: 1.5555556;
}

.prose-xl :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0.6666667em;
  padding-bottom: 0.8888889em;
  padding-inline-start: 0.6666667em;
}

.prose-xl :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-xl :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-top: 0.8888889em;
  padding-inline-end: 0.6666667em;
  padding-bottom: 0.8888889em;
  padding-inline-start: 0.6666667em;
}

.prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-xl :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-xl :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  font-size: 0.9em;
  line-height: 1.5555556;
  margin-top: 1em;
}

.prose-xl :where(.prose-xl > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(.prose-xl > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin-bottom: 0;
}

[id] {
  scroll-margin-top: 40px;
}

[is="builder-form"] select {
  appearance: none !important;
  background-image: none !important;
}

[is="builder-form"] [type="checkbox"]:checked:hover,
[is="builder-form"] [type="checkbox"]:checked:focus,
[is="builder-form"] [type="radio"]:checked:hover,
[is="builder-form"] [type="radio"]:checked:focus {
  border-color: transparent;
  background-color: transparent;
}

[is="builder-form"] [type="checkbox"],
[is="builder-form"] [type="radio"] {
  background-color: white !important;
}

[is="builder-form"] input:valid,
[is="builder-form"] input:autofill {
  background-color: white !important;
}

/* NOT possible to set background color in Chrome, see
https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill
 */

/* [is='builder-form'] input:-webkit-autofill {
  background-color: white !important;
} */

/* visually hide OneTrust bottom-left-corner cookie banner (still screen-reader accessible however) */

#ot-sdk-btn-floating {
  position: absolute !important;
  left: -10000px !important;
  top: auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

/* Slider Dots (eg see app builder page carousel) */

/* .w-slider-dot */

.glide__bullet {
  height: 16px !important;
  width: 16px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  /* background-color: #d9d9d9 !important; */
  border-radius: 50%;
  border: 1px solid #7c4dff;
}

/* .w-slider-dot.w-active */

.glide__bullet--active {
  background-color: #7c4dff !important;
}

.glide__bullets--white .glide__bullet {
  border: 1px solid white;
}

.glide__bullets--white .glide__bullet--active {
  background-color: white !important;
}

.glide__bullets--purple .glide__bullet {
  background-color: #f3ebff !important;
  border: none;
}

.glide__bullets--purple .glide__bullet--active {
  background-color: #7c4dff !important;
}

/* .w-richtext:after,.w-richtext:before {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
} */

/* .w-richtext:after {
  clear: both
} */

.w-richtext[contenteditable="true"]:after,
.w-richtext[contenteditable="true"]:before {
  white-space: initial;
}

/* .w-richtext ol,
.w-richtext ul {
  overflow: hidden;
} */

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type="image"] div,
.w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:after {
  outline: #7c4dff solid 2px;
}

.w-richtext figure.w-richtext-figure-type-video > div:after,
.w-richtext figure[data-rt-type="video"] > div:after {
  content: "";
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.w-richtext figure {
  position: relative;
  max-width: 60%;
}

.w-richtext figure > div:before {
  cursor: default !important;
}

.w-richtext figure img {
  width: 100%;
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
  opacity: 0.6;
}

.w-richtext figure div {
  font-size: 0;
  color: transparent;
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type="image"] {
  display: table;
}

.w-richtext figure.w-richtext-figure-type-image > div,
.w-richtext figure[data-rt-type="image"] > div {
  display: inline-block;
}

.w-richtext figure.w-richtext-figure-type-image > figcaption,
.w-richtext figure[data-rt-type="image"] > figcaption {
  display: table-caption;
  caption-side: bottom;
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type="video"] {
  width: 60%;
  height: 0;
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type="video"] iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.w-richtext figure.w-richtext-figure-type-video > div,
.w-richtext figure[data-rt-type="video"] > div {
  width: 100%;
}

.w-richtext figure.w-richtext-align-center {
  margin-right: auto;
  margin-left: auto;
  clear: both;
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image > div,
.w-richtext figure.w-richtext-align-center[data-rt-type="image"] > div {
  max-width: 100%;
}

.w-richtext figure.w-richtext-align-normal {
  clear: both;
}

.w-richtext figure.w-richtext-align-fullwidth {
  width: 100%;
  max-width: 100%;
  text-align: center;
  clear: both;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.w-richtext figure.w-richtext-align-fullwidth > div {
  display: inline-block;
  padding-bottom: inherit;
}

.w-richtext figure.w-richtext-align-fullwidth > figcaption {
  display: block;
}

.w-richtext figure.w-richtext-align-floatleft {
  float: left;
  margin-right: 15px;
  clear: none;
}

.w-richtext figure.w-richtext-align-floatright {
  float: right;
  margin-left: 15px;
  clear: none;
}

/* https://stackblitz.com/edit/shopify-hydrogen-j4ackq?file=src%2Fstyles%2Findex.css&title=Hydrogen */

/* .swimlane {
    @apply grid snap-mandatory grid-flow-col snap-x w-full gap-4 scroll-px-6 px-6 pb-4 overflow-x-scroll justify-start;
  } */

details summary::-webkit-details-marker {
  display: none;
}

.faq-drop-element summary {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.faq-drop-element summary::before {
  content: "";
  width: 10px;
  height: 10px;
  border-left: 6px black solid;
  border-top: 6px transparent solid;
  border-bottom: 6px transparent solid;
  margin-top: 5px;
}

.faq-drop-element[open] summary::before {
  transform: rotate(90deg);
}

.natasha-bg {
  background: linear-gradient(135deg, #f4eefc 0%, #faeeec 100%);
  clip-path: ellipse(100% 100% at 50% -20%);
  min-height: 600px;
  position: absolute;
  width: 100%;
  z-index: -1;
  left: 0;
  height: 800px;
}

@media (max-width: 768px) {
  .natasha-bg {
    display: none;
  }
}

@media (min-width: 1000px) {
  .natasha-bg {
    clip-path: ellipse(100% 100% at 50% -35%);
  }
}

@media (min-width: 1300px) {
  .natasha-bg {
    clip-path: ellipse(100% 100% at 50% -42%);
  }
}

.natasha-chat {
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.icon-box > * {
  max-width: 100%;
}

/* loading spinner */

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-default 0.5s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.05s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.1s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.15s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.2s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.25s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.3s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.25s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.4s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.45s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -0.5s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -0.55s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-top-1
 * ----------------------------------------
 */

.tilt-in-top-1 {
  -webkit-animation: tilt-in-top-1 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    forwards;
  animation: tilt-in-top-1 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@-webkit-keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-200px) translateX(-100px)
      skewY(-20deg);
    transform: rotateY(30deg) translateY(-200px) translateX(-100px)
      skewY(-20deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0deg) translateY(0) translateX(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) translateX(0) skewY(0deg);
    opacity: 1;
  }
}

@keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-200px) translateX(-100px)
      skewY(-20deg);
    transform: rotateY(30deg) translateY(-200px) translateX(-100px)
      skewY(-20deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0deg) translateY(0) translateX(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) translateX(0) skewY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * Range slider
 * ----------------------------------------
 */

:root {
  --prodInfo: "Get your app screens designed up for you – to see your app idea come to life.";
  --prodHeading: "Custom prototype";
  --prodPrice: "";
}

.range-slider {
  --primary-color: linear-gradient(90deg, #b54afa 30%, #6933e8 100%);
  --value-offset-y: var(--ticks-gap);
  --value-active-color: white;
  --value-background: transparent;
  --value-background-hover: var(--primary-color);
  --value-font: 300 12px/1 "Rubik", sans-serif;
  --fill-color: var(--primary-color);
  --progress-background: #f3ebff;
  --progress-radius: 50px;
  --track-height: var(--thumb-size);
  --min-max-font: 12px Arial;
  --min-max-opacity: 0.5;
  --min-max-x-offset: 10%;
  --thumb-size: 50px;
  --thumb-color: white;
  --thumb-shadow:
    0 0 3px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 0 0 99px
    var(--thumb-color) inset;
  --thumb-shadow-active:
    0 0 0 calc(var(--thumb-size) / 4) inset var(--thumb-color), 0 0 0 99px var(
    --primary-color
  ) inset, 0 0 3px rgba(0, 0, 0, 0.2);
  --thumb-shadow-hover: var(--thumb-shadow);
  --ticks-thickness: 1px;
  --ticks-height: 15px;
  --ticks-gap: var(--ticks-height, 0);
  --step: 1;
  --ticks-count: Calc(var(--max) - var(--min)) / var(--step);
  --maxTicksAllowed: 30;
  --too-many-ticks: Min(1, Max(var(--ticks-count) - var(--maxTicksAllowed), 0));
  --x-step: Max(var(--step), var(--too-many-ticks) * (var(--max) - var(--min)));
  --tickIntervalPerc: calc(
    (100% - var(--thumb-size)) / ((var(--max) - var(--min)) / var(--x-step))
      * var(--tickEvery, 1)
  );
  --value-a: Clamp(var(--min), var(--value, 0), var(--max));
  --value-b: var(--value, 0);
  --text-value-a: var(--text-value, "");
  --completed-a: calc(
    (var(--value-a) - var(--min)) / (var(--max) - var(--min)) * 100
  );
  --completed-b: calc(
    (var(--value-b) - var(--min)) / (var(--max) - var(--min)) * 100
  );
  --ca: Min(var(--completed-a), var(--completed-b));
  --cb: Max(var(--completed-a), var(--completed-b));
  --thumbs-too-close: Clamp(
    -1,
    1000 * (Min(1, Max(var(--cb) - var(--ca) - 5, -1)) + 0.001),
    1
  );
  --thumb-close-to-min: Min(1, Max(var(--ca) - 2, 0));
  --thumb-close-to-max: Min(1, Max(98 - var(--cb), 0));
  display: inline-block;
  height: var(--thumb-size);
  background-position-x: calc(
    var(--thumb-size) / 2 - var(--ticks-thickness) / 2
  );
  background-position-y: var(--flip-y, bottom);
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .range-slider {
    --thumb-size: 70px;
  }
}

.range-slider[data-ticks-position="top"] {
  --flip-y: 1;
}

.range-slider__values {
  position: relative;
  top: 50%;
  line-height: 0;
  text-align: justify;
  width: 100%;
  pointer-events: none;
  margin: 0 auto;
  z-index: 5;
}

.range-slider__values::after {
  content: "";
  width: 100%;
  display: inline-block;
  height: 0;
  background: red;
}

.range-slider__progress {
  --start-end: calc(var(--thumb-size) / 2);
  --clip-end: calc(100% - var(--cb) * 1%);
  --clip-start: calc(var(--ca) * 1%);
  --clip: polygon(0% 0, var(--pos) 0%, var(--pos) 100%, 0 100%);
  /* --clip: xywh(0 0px calc(100% - (var(--clip-end)) + (var(--clip-end) / 10)) 100% round 40px 40px); */
  position: absolute;
  left: 0;
  right: 0;
  height: var(--track-height);
  background: var(--progress-background, #eee);
  pointer-events: none;
  z-index: -1;
  border-radius: var(--progress-radius);
}

.range-slider__progress::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  -webkit-clip-path: var(--clip);
  clip-path: var(--clip);
  top: 0;
  bottom: 0;
  background: var(--fill-color, black);
  box-shadow: var(--progress-flll-shadow);
  z-index: 1;
  border-radius: inherit;
}

.range-slider__progress::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: var(--progress-shadow);
  pointer-events: none;
  border-radius: inherit;
}

.range-slider > input {
  -webkit-appearance: none;
  width: 100%;
  height: var(--thumb-size);
  margin: 0;
  position: absolute;
  left: 0;
  cursor: -webkit-grab;
  cursor: grab;
  outline: none;
  background: none;
}

.range-slider > input:not(:only-of-type) {
  pointer-events: none;
}

.range-slider > input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: calc(var(--thumb-size) - 6px);
  width: calc(var(--thumb-size) - 6px);
  transform: var(--thumb-transform);
  border-radius: 100%;
  background: var(--thumb-color);
  box-sizing: content-box;
  border: 3px #b54afa solid;
  pointer-events: auto;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.range-slider > input::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  height: var(--thumb-size);
  width: calc(var(--thumb-size) * 2.8);
  transform: var(--thumb-transform);
  border-radius: var(--thumb-radius, 16px);
  background: var(--thumb-color);
  box-shadow: var(--thumb-shadow);
  border: none;
  pointer-events: auto;
  -moz-transition: 0.1s;
  transition: 0.1s;
}

.range-slider > input::-ms-thumb {
  appearance: none;
  height: var(--thumb-size);
  width: calc(var(--thumb-size) * 2.8);
  transform: var(--thumb-transform);
  border-radius: var(--thumb-radius, 16px);
  background: var(--thumb-color);
  box-shadow: var(--thumb-shadow);
  border: none;
  pointer-events: auto;
  -ms-transition: 0.1s;
  transition: 0.1s;
}

.range-slider > input + output .inner {
  margin: 70px 0 0 0px;
  z-index: 1;
  position: absolute;
  width: 135px;
  font-weight: 500;
  display: none;
  color: black;
}

@media (min-width: 992px) {
  .range-slider > input + output .inner {
    margin: 90px 0 0 0px;
  }
}

.range-slider > input + output .inner::before,
.range-slider > input + output .inner::after {
  content: var(--prodPrice);
  width: 270px;
  display: block;
  left: -67px;
  position: relative;
  padding: 0 20px;
  text-align: left;
}

@media (max-width: 992px) {
  .range-slider > input + output .inner::after {
    font-size: 16px;
  }

  .range-slider > input + output .inner::before,
  .range-slider > input + output .inner::after {
    left: -77px;
  }

  .range-slider > input + output.min .inner::before,
  .range-slider > input + output.min .inner::after {
    left: 13px;
  }

  .range-slider > input + output.min-1 .inner::before,
  .range-slider > input + output.min-1 .inner::after {
    left: -47px;
  }

  .range-slider > input + output.max-1 .inner::before,
  .range-slider > input + output.max-1 .inner::after {
    left: -97px;
  }

  .range-slider > input + output.max .inner::before,
  .range-slider > input + output.max .inner::after {
    left: -167px;
  }
}

@media (min-width: 992px) {
  .range-slider > input + output .inner::before {
    font-size: 24px;
    left: -67px;
  }
}

.range-slider > input + output .inner::after {
  content: var(--prodHeading);
}

.range-slider > input:active + output .inner,
.range-slider > input:hover + output .inner {
  display: block;
}

.range-slider > input:active + output:before,
.range-slider > input:hover + output:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  top: 53px;
  box-shadow: -1px -1px 0px #d9c4ff;
  z-index: 1;
  transform: rotate(45deg);
  border-radius: 2px;
  background: #fbf8ff;
  left: 60px;
}

@media (min-width: 992px) {
  .range-slider > input:hover + output:before {
    top: 73px;
    left: 68px;
  }
}

.range-slider > input:active + output:after,
.range-slider > input:hover + output:after {
  content: var(--prodInfo);
  position: absolute;
  width: 280px;
  left: -70px;
  top: 60px;
  padding: 74px 20px 20px;
  border-radius: 20px;
  background: #fbf8ff;
  /* box-shadow: var(--thumb-shadow); */
  border: 1px #d9c4ff solid;
  color: black;
  font-weight: 400;
}

@media (max-width: 992px) {
  .range-slider > input:hover + output:after {
    padding: 64px 20px 20px;
  }

  .range-slider > input:hover + output.min:after {
    left: 20px;
  }

  .range-slider > input:hover + output.min-1:after {
    left: -40px;
  }

  .range-slider > input:hover + output.max-1:after {
    left: -90px;
  }

  .range-slider > input:hover + output.max:after {
    left: -160px;
  }
}

@media (min-width: 992px) {
  .range-slider > input:hover + output:after {
    top: 80px;
    width: 270px;
    left: -60px;
  }
}

.range-slider > input:nth-of-type(1) {
  --is-left-most: Clamp(0, (var(--value-a) - var(--value-b)) * 99999, 1);
}

.range-slider > input:nth-of-type(1) + output {
  --x-offset: calc(var(--completed-a) * -1%);
}

.range-slider > input:nth-of-type(1) + output:not(:only-of-type) {
  --flip: calc(var(--thumbs-too-close) * -1);
}

.range-slider > input:nth-of-type(2) {
  --is-left-most: Clamp(0, (var(--value-b) - var(--value-a)) * 99999, 1);
}

.range-slider > input:only-of-type ~ .range-slider__progress {
  --clip-start: 0;
}

:root {
  --pos: 50%;
}

.range-slider > input + output {
  --flip: 0;
  --x-offset: calc(var(--completed-b) * -1%);
  font-weight: 300;
  pointer-events: none;
  position: absolute;
  z-index: 5;
  background: var(--value-background);
  border-radius: 10px;
  padding: 2px 8px;
  left: calc(var(--pos) - 75px);
  top: 10px;
  justify-content: center;
  display: flex;
  transition: all 0.12s ease-out, left 0s;
  width: 150px;
  /* text-align: center; */
}

@media (min-width: 992px) {
  .range-slider > input + output {
    left: calc(var(--pos) - 73px);
    top: 20px;
  }
}

.range-slider-container {
  display: grid;
  grid-template-columns: 0px 1fr 0px;
  place-content: space-evenly;
  place-items: center;
  border-radius: 100px;
  /* background: rgba(98, 0, 234, 0.1); */
  height: var(--thumb-size);
  color: #6933e8;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 992px) {
  .range-slider-container {
    grid-template-columns: 60px 1fr 60px;
  }
}

div#maxValue:first-letter,
div#minValue:first-letter {
  color: black;
}

/**
Blog banner
**/

#BlogFormBanner.closed {
  max-height: 80px;
}

@media (min-width: 768px) {
  #BlogFormBanner.closed {
    max-height: 120px;
  }
}

@media (max-width: 768px) {
  #BlogFormBanner.closed h3 {
    display: none;
  }
}

#BlogFormBanner.closed #blogBannerCta,
#BlogFormBanner.closed #mobileBlogBannerImg {
  display: none;
}

#BlogFormBanner.closed img {
  height: 110px;
}

#BlogFormBanner.closed #blogBannerImg {
  width: 170px;
}

#BlogFormBanner.closed #blogBannerInner {
  padding-top: 1.2rem;
}

#BlogFormBanner.closed #toggleBlogBanner {
  transform: rotate(0);
}

@media (min-width: 480px) {
  .b-studio-vid {
    min-height: 300px;
    min-width: 520px;
  }
}

::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.feature-detail {
  width: 100%;
}

.feature-detail summary {
  display: flex;
  justify-content: space-between;
}

.feature-detail summary:after {
  content: "";
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2229%22%20viewBox%3D%220%200%2020%2029%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M13.75%2013.437L10.1767%2017.01C10.1298%2017.0569%2010.0663%2017.0833%2010%2017.0833C9.93372%2017.0833%209.87017%2017.0569%209.82333%2017.01L6.25%2013.437%22%20stroke%3D%22black%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  width: 20px;
  height: 30px;
}

.feature-detail[open] summary:after {
  transform: rotate(180deg);
}

.gitex-banner {
  background-image: url("/images/builder-event-background-center-mobile.jpg");
  background-position: left bottom;
  min-height: 200vh;
  background-size: 144vw !important;
}

.smb-banner-bg {
  background-image: url("/images/smb-banner-bg.png");
  /* background-position: left bottom; */
  /* min-height: 200vh; */
  /* background-size: 144vw !important; */
}

.border-gradient {
  border-image: linear-gradient(to right, #3acfd5 0%, #3a4ed5 100%) 1;
  border: 6px soild;
}

.gitex-events {
  background-image: url("/images/builder-event-background-center-mobile.jpg");
  background-color: #210e33;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media (min-width: 768px) {
  .gitex-banner {
    background-image: url("/images/builder-event-background.jpg");
    background-position-x:center;
    background-position-y: bottom;
    padding-bottom: 0;
    min-height: auto;
    padding: 0px 32px;
    height: calc(100vh - 80px);
  }

  .gitex-events {
    background-image: url("/images/builder-event-background-center.jpg");
    background-position: center bottom;
    background-size: cover;
  }
}

.tabs-row button[aria-selected="true"] {
  border-bottom: 4px #00d659 solid;
}

@media (min-width: 768px) {
  .tabs-row button[aria-selected="true"] {
    background: none;
    color: white;
    font-weight: 500;
  }

  .tabs-row button {
    color: #fff;
    font-weight: 400;
  }
}

dialog::backdrop {
  opacity: 0.75;
  backdrop-filter: blur(10px);
}

.gradient-bg {
  background: linear-gradient(135deg, #f4eefc 0%, #faeeec 100%);
  z-index: -1;
  /* left: 0;
  height: 800px; */
}
